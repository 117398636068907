import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {TeamMember, TeamMemberRole} from "../model/resources/member";

@Injectable({
  providedIn: 'root'
})
export class MembersApiService {

  constructor(private apiService: ApiService,
  ) {
  }

  async getTeamMembers(teamId: number, role: TeamMemberRole, hideLoading?: boolean): Promise<TeamMember[] | null> {
    try {
      return await this.apiService.get(teamId, `members?role=${role}`, hideLoading);
    } catch (e) {
      return null;
    }
  }

  async getTeamMember(teamId: number, userId: number, hideLoading?: boolean): Promise<TeamMember | null> {
    try {
      return await this.apiService.get(teamId, `members/${userId}`, hideLoading);
    } catch (e) {
      return null;
    }
  }

  async patchTeamMemberRole(teamId: number, userId: number, role: TeamMemberRole, hideLoading?: boolean, forceExecution?: boolean): Promise<boolean> {
    try {
      await this.apiService.patch(teamId, `members/${userId}`, {role}, hideLoading, forceExecution);
      return true;
    } catch (e) {
      return false;
    }
  }
}
